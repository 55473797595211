import { useCallback, useMemo } from 'react';
import { first, rejectNullable } from '@helpers/data';
import { AppFCC, ExcludeNil } from '@types';
import { Link, LinkProps } from '@managers/links/link';
import { AccessControlLinkProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AccessControlLink: AppFCC<AccessControlLinkProps> = props => {
  const {
    routeDescriptor,
    routesHighlighting,
    accessControlCallbacks,
    checkActive,
    children,
    onClick,
    ...rest
  } = props;
  const accessControlCallback = useMemo(() => first(rejectNullable(accessControlCallbacks)), [accessControlCallbacks]);
  const click: ExcludeNil<LinkProps['onClick']> = useCallback(event => {
    event.preventDefault();
    accessControlCallback?.(event);
    onClick?.(event);
  }, [accessControlCallback, onClick]);
  return _jsx(Link //
  , {
    routeDescriptor: routeDescriptor,
    routesHighlighting: routesHighlighting,
    checkActive: checkActive,
    onClick: accessControlCallback //
    ? click : onClick,
    ...rest,
    children: children
  });
};