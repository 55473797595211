import { useMemo } from 'react';
import { AppFCC } from '@types';
import { footerAddressIconClassName, FooterAddressIconsListStyled, FooterAddressStyled, FooterAddressTextStyled } from '@kit/footer/footer-address/styled';
import { MastercardIcon, MastercardIdCheckIcon, SecureIcon, VisaIcon, VisaSecureIcon } from '@icons';
import { FooterAddressProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const cardIcons = [{
  Icon: SecureIcon
}, {
  Icon: MastercardIdCheckIcon
}, {
  Icon: MastercardIcon
}, {
  Icon: VisaSecureIcon
}, {
  Icon: VisaIcon
}];
export const FooterAddress: AppFCC<FooterAddressProps> = props => {
  const {
    className
  } = props;
  const cardIconsList = useMemo(() => {
    return cardIcons.map(({
      Icon
    }, index) => {
      return _jsx("li", {
        children: _jsx(Icon, {
          className: footerAddressIconClassName
        })
      }, index);
    });
  }, []);
  return _jsxs(FooterAddressStyled, {
    className: className,
    children: [_jsx(FooterAddressIconsListStyled, {
      children: cardIconsList
    }), _jsx(FooterAddressTextStyled, {
      children: "Operating License granted to Interactive Online Technologies BV, Reg.Nr. 90081536, Netherlands, Amsterdam, Kingsfordweg 151, 1043 GR, and to MediaMars, Limited Partnership, Reg.Nr. 558163671, 4 Ariel Sharon, Givatayim, Israel. The website is owned by Rover Media LTD. Legal address: Israel, Givatayim, Ariel Sharon 4, tax ID: 516214392"
    })]
  });
};