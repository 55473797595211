import { useMemo } from 'react';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { useNavigator } from '@hooks/routing';
import { Link } from '@managers/links/link';
import { LinkQueryProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LinkQuery: AppFCC<LinkQueryProps> = props => {
  const {
    children,
    query: _query,
    ...restProps
  } = props;
  const navigator = useNavigator();
  const pathname = navigator.getPathname();
  const query = navigator.getQuery();
  const routeDescriptor = useMemo(() => {
    const match = navigator.findMatch(pathname);
    if (!match) {
      return ROUTES.index.getDescriptor(query);
    }
    return match.route.getDescriptor({
      ...query,
      ...match.params,
      ..._query
    });
  }, [navigator, pathname, query, _query]);
  return _jsx(Link //
  , {
    routeDescriptor: routeDescriptor,
    ...restProps,
    children: children
  });
};