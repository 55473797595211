import { AppFCC } from '@types';
import { ExternalLinkProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LinkExternal: AppFCC<ExternalLinkProps> = props => {
  const {
    children,
    ...restProps
  } = props;
  const externalLinkProps = {
    target: '_blank',
    rel: 'noopener nofollow',
    ...restProps
  };
  return _jsx("a", {
    ...externalLinkProps,
    children: children
  });
};